import constantTestBuilder from "../../mixins/Support/constantTestBuilder";
import {mapState} from "vuex";

export default {
  mixins: [
    constantTestBuilder,
  ],
  computed: {
    ...mapState({
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    product_category_id: {
      get() {
        return this.$store.state.informationTestBuilderStore.product_category_id;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setProductCategoryId", value);
      },
    },

  },
  methods: {
    canInputCourseTopic() {
      if (
        this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH &&
        this.type_expand === this.test_type_expand_constant.TEST
      ) {
        return true;
      }
      return false;
    },
    canInputLevel() {
      if (
        this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH_V2 &&
        this.type_expand === this.test_type_expand_constant.TEST
      ) {
        return true;
      }
      return false;
    },
    canInputSituationCodeForTestInfo() {
      if (
        [this.product_category_constant.PREPTALK_ENGLISH, this.product_category_constant.PREPTALK_ENGLISH_V2].includes(this.product_category_id) &&
        this.type_expand === this.test_type_expand_constant.TEST
      ) {
        return true;
      }
      return false;
    },
  },
  filters: {}
}